/* src/App.css */
body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  width: 30vw;
  background-color: rgb(117, 114, 183);
  padding: 2vw 1vw;
  border-radius: 10px 10px 0 0;
  font-size: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 30vw;
  padding: 2vw 1vw;
  background-color: rgb(151, 224, 166);
  border-radius: 0 0 10px 10px;
}

input {
  border: 1px solid rgb(172, 171, 171);
  border-radius: 10px;
  padding: 1vw 1vw;
  outline: none;
  margin: 5px;
}
